import React, { useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import emailjs from "emailjs-com";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import DesignIllustration from "../../images/doctor.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row-reverse lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-teal-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-teal-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-teal-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none enabled:hover:bg-teal-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const AnimationMail = styled.span`
.container {
  ${tw`flex justify-center lg:justify-start`}
}

.animation {
  ${tw`h-10 lg:h-16 overflow-hidden`}
}

.animation > div > div {
  height:2.81rem;
  margin-bottom: 2.81rem;
  display:inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

@keyframes text-animation {
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.6rem;}
  30% {margin-top: -5.6rem;}
  40% {margin-top: -11.2rem;}
  60% {margin-top: -11.2rem;}
  70% {margin-top: -5.6rem;}
  80% {margin-top: -5.6rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
}

`;

export default ({ roundedHeaderButton }) => {


  const [value, setValue] = useState({submitted: false, loading: false});
  const features = ["Votre e-mail pour 35€/an", "7 jours d'essais gratuit", "Un service client réactif"];
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setValue({loading: true});
    emailjs.sendForm('service_rcju0hz', 'template_5an1gnp', form.current, 'M3bq24r32oRAULjbQ')
      .then((result) => {
          console.log('SUCCESS!', result.status, result.text);
          setValue({submitted: true, loading: false});
          console.log('submitted: ', value);
      }, (error) => {
          console.log('ERROR!', error.status, error.text);
      });
  };
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
          <LeftColumn>
            <Heading>
              <AnimationMail>
                <div className="container">
                  <section className="animation">
                    <div><div>patrick.levy</div></div>
                    <div><div>julie.combe</div></div>
                    <div><div>theo.durant</div></div>
                  </section>
                  <span tw="text-teal-500">@dr.fr</span>
                </div>
              </AnimationMail>
            </Heading>
            <Paragraph>
              Vous êtes un professionnel de santé ? <br></br>Réservez une adresse e-mail adapté à votre activité !
            </Paragraph>
            <Actions>
              <form ref={form} onSubmit={sendEmail}>
                <input type="email" name="client_email" placeholder="Votre adresse e-mail" required/>
                {!value.submitted ? (
                <button type="submit" className="animate-spin">
                  {!value.loading ? ("S'inscrire") : (
                    <svg tw="stroke-4 animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle tw="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"></circle>
                      <path tw="opacity-75" fill="#fff" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>)}
                </button> ) : (
                  (<button type="submit" className="animate-spin" disabled>
                  Envoyé !
                </button>))}
              </form>
            </Actions>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
            {/* <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList> */}
          </LeftColumn>
        </TwoColumn>
        {/* <DecoratorBlob1 /> */}
      </Container>
    </>
  );
};
